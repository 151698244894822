var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',{staticClass:"mx-auto",attrs:{"max-width":"1800"}},[_c('v-toolbar',{attrs:{"color":"#0F0F0F","dark":""}},[_c('v-spacer'),_c('v-toolbar-title',[_vm._v("Sales")]),_c('v-spacer'),_c('v-spacer'),_c('v-text-field',{attrs:{"prepend-icon":"mdi-magnify","placholder":"Search","label":"Search"},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-data-table',{staticClass:"elevation-1",attrs:{"headers":_vm.headers,"items":_vm.salesFiltered,"items-per-page":15,"dense":""},scopedSlots:_vm._u([{key:"item.edit",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"id":item.id,"small":"","dark":"","color":"blue darken-3"},on:{"click":_vm.editItem}},[_c('span',{staticStyle:{"font-size":"0.905em"}},[_vm._v("Edit")])])]}},{key:"item.files",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"id":item.id,"small":"","dark":"","color":"green darken-3"},on:{"click":_vm.showFiles}},[_c('span',{staticClass:"mdi mdi-file-multiple"})])]}},{key:"item.signOff",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"id":item.id,"small":"","dark":"","color":"grey darken-3"},on:{"click":_vm.openSignOff}},[_c('span',{staticClass:"mdi mdi-checkbox-marked"})])]}},{key:"item.email",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"id":item.id,"small":"","dark":"","color":"purple darken-3"},on:{"click":_vm.emailItem}},[_c('span',{staticStyle:{"font-size":"0.905em"}},[_vm._v("Email")])])]}},{key:"item.delete",fn:function(ref){
var item = ref.item;
return [_c('v-chip',{attrs:{"id":item.id,"small":"","dark":"","color":"red darken-3"},on:{"click":_vm.deleteItem}},[_c('span',{staticStyle:{"font-size":"0.905em"}},[_vm._v("X")])])]}}])}),(_vm.salesEditData.length > 0)?_c('ClientUpsert',{attrs:{"upsertMode":_vm.upsertMode,"dialog":_vm.clientDialog,"editData":_vm.salesEditData,"unitValue":_vm.unitValue,"planType":_vm.planType,"unitId":_vm.unitId},on:{"closeForm":_vm.closeClientForm}}):_vm._e(),(_vm.clientFilesData.length > 0)?_c('ClientFiles',{attrs:{"dialogFiles":_vm.clientFileDialog,"fileData":_vm.clientFilesData},on:{"closeForm":_vm.closeClientFiles}}):_vm._e(),(_vm.signOffData.length > 0)?_c('SignOff',{attrs:{"dialogFiles":_vm.signOffDialog,"fileData":_vm.signOffData},on:{"closeForm":_vm.closeSignOffForm}}):_vm._e()],1)}
var staticRenderFns = []

export { render, staticRenderFns }