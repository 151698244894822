var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"about"},[_c('br'),_c('br'),_c('br'),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogFiles),callback:function ($$v) {_vm.dialogFiles=$$v},expression:"dialogFiles"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Sign Off OTP")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[(
                     _vm.fileData[0].fileOTP !== null &&
                       _vm.fileData[0].fileOTP !== '' &&
                       _vm.fileData[0].fileOTP !== 'undefined'
                   )?_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('span',[_vm._v("Signed OTP")])]):_vm._e(),(
                     _vm.fileData[0].fileOTP === null ||
                       _vm.fileData[0].fileOTP === '' ||
                       _vm.fileData[0].fileOTP === 'undefined'
                   )?_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('span',[_vm._v("No OTP Document uploaded yet")])]):_vm._e(),(
                     _vm.fileData[0].fileOTP !== null &&
                       _vm.fileData[0].fileOTP !== '' &&
                       _vm.fileData[0].fileOTP !== 'undefined'
                   )?_c('v-col',{attrs:{"cols":"6","sm":"8","md":"8","lg":"8"}},[_c('v-spacer'),_c('v-file-input',{attrs:{"label":"Upload OTP","accept":"image/png, image/jpeg, image/bmp, image/jpg, application/pdf","filled":"","hint":"OTP","persistent-hint":""},model:{value:(_vm.fileOTP),callback:function ($$v) {_vm.fileOTP=$$v},expression:"fileOTP"}})],1):_vm._e()],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeSignOffForm}},[_vm._v(" Close ")]),_c('v-btn',{attrs:{"text":"","color":"primary","elevation":"3","outlined":"","rounded":""},on:{"click":_vm.updateClientOTP}},[_vm._v(" Save ")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }