var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-container',[_c('div',{staticClass:"about"},[_c('br'),_c('br'),_c('br'),_c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"max-width":"500px"},model:{value:(_vm.dialogFiles),callback:function ($$v) {_vm.dialogFiles=$$v},expression:"dialogFiles"}},[_c('v-card',[_c('v-card-title',[_c('span',{staticClass:"text-h5"},[_vm._v("Client Files")])]),_c('v-card-text',[_c('v-container',[_c('v-row',[(
                    _vm.fileData[0].fileOTP !== 'undefined' &&
                      _vm.fileData[0].fileOTP.length > 0 &&
                      _vm.fileData[0].fileOTP !== null
                  )?_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('span',[_vm._v("OTP")])]):_vm._e(),(
                    _vm.fileData[0].fileOTP !== 'undefined' &&
                      _vm.fileData[0].fileOTP.length > 0 &&
                      _vm.fileData[0].fileOTP !== null
                  )?_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('v-spacer'),_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":("" + _vm.url + (_vm.fileData[0].fileOTP)),"download":"","target":"_blank"}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-file-pdf-box")])],1)],1):_vm._e(),(
                    _vm.fileData[0].fileId !== 'undefined' &&
                      _vm.fileData[0].fileId.length > 0 &&
                      _vm.fileData[0].fileId !== null
                  )?_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('span',[_vm._v("ID/Passport ")])]):_vm._e(),(
                    _vm.fileData[0].fileId !== 'undefined' &&
                      _vm.fileData[0].fileId.length > 0 &&
                      _vm.fileData[0].fileId !== null
                  )?_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('v-spacer'),_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":("" + _vm.url + (_vm.fileData[0].fileId)),"download":"","target":"_blank"}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-file-pdf-box")])],1)],1):_vm._e(),(
                    _vm.fileData[0].fileBank.length > 0 &&
                      _vm.fileData[0].fileBank !== 'undefined' &&
                      _vm.fileData[0].fileBank !== null
                  )?_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('span',[_vm._v("Bank Statement")])]):_vm._e(),(
                    _vm.fileData[0].fileBank.length > 0 &&
                      _vm.fileData[0].fileBank !== 'undefined' &&
                      _vm.fileData[0].fileBank !== null
                  )?_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('v-spacer'),_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":("" + _vm.url + (_vm.fileData[0].fileBank)),"download":"","target":"_blank"}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-file-pdf-box")])],1)],1):_vm._e(),(
                    _vm.fileData[0].fileDepositPop.length > 0 &&
                      _vm.fileData[0].fileDepositPop !== 'undefined' &&
                      _vm.fileData[0].fileDepositPop !== null
                  )?_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('span',[_vm._v("Deposit POP")])]):_vm._e(),(
                    _vm.fileData[0].fileDepositPop.length > 0 &&
                      _vm.fileData[0].fileDepositPop !== 'undefined' &&
                      _vm.fileData[0].fileDepositPop !== null
                  )?_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('v-spacer'),_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":("" + _vm.url + (_vm.fileData[0].fileDepositPop)),"download":"","target":"_blank"}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-file-pdf-box")])],1)],1):_vm._e(),(
                    _vm.fileData[0].filePaySlip.length > 0 &&
                      _vm.fileData[0].filePaySlip !== 'undefined' &&
                      _vm.fileData[0].filePaySlip !== null
                  )?_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('span',[_vm._v("Payslip")])]):_vm._e(),(
                    _vm.fileData[0].filePaySlip.length > 0 &&
                      _vm.fileData[0].filePaySlip !== 'undefined' &&
                      _vm.fileData[0].filePaySlip !== null
                  )?_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":("" + _vm.url + (_vm.fileData[0].filePaySlip)),"download":"","target":"_blank"}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-file-pdf-box")])],1)]):_vm._e(),(
                    _vm.fileData[0].fileFica.length > 0 &&
                      _vm.fileData[0].fileFica !== 'undefined' &&
                      _vm.fileData[0].fileFica !== null
                  )?_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('span',[_vm._v("Proof of Address")])]):_vm._e(),_c('ul',[(
                      _vm.fileData[0].fileFica.length > 0 &&
                        _vm.fileData[0].fileFica !== 'undefined' &&
                        _vm.fileData[0].fileFica !== null
                    )?_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('v-spacer'),_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":("" + _vm.url + (_vm.fileData[0].fileFica)),"download":"","target":"_blank"}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-file-pdf-box")])],1)],1):_vm._e()],1)],1),_c('v-row',[(
                    _vm.fileData[0].personTwoFileID &&
                      _vm.fileData[0].personTwoFileID.length > 0 &&
                      _vm.fileData[0].personTwoFileID !== 'undefined'
                  )?_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('span',[_vm._v("ID/Passport")])]):_vm._e(),(
                    _vm.fileData[0].personTwoFileID &&
                      _vm.fileData[0].personTwoFileID.length > 0 &&
                      _vm.fileData[0].personTwoFileID !== 'undefined'
                  )?_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('v-spacer'),_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":("" + _vm.url + (_vm.fileData[0].personTwoFileID)),"download":"","target":"_blank"}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-file-pdf-box")])],1)],1):_vm._e(),(
                    _vm.fileData[0].personTwoFileBank &&
                      _vm.fileData[0].personTwoFileBank.length > 0 &&
                      _vm.fileData[0].personTwoFileBank !== 'undefined'
                  )?_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('span',[_vm._v("Bank Statement")])]):_vm._e(),(
                    _vm.fileData[0].personTwoFileBank &&
                      _vm.fileData[0].personTwoFileBank.length > 0 &&
                      _vm.fileData[0].personTwoFileBank !== 'undefined'
                  )?_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('v-spacer'),_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":("" + _vm.url + (_vm.fileData[0].personTwoFileBank)),"download":"","target":"_blank"}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-file-pdf-box")])],1)],1):_vm._e(),(
                    _vm.fileData[0].personTwoFilePayslip &&
                      _vm.fileData[0].personTwoFilePayslip.length > 0 &&
                      _vm.fileData[0].personTwoFilePayslip !== 'undefined'
                  )?_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('span',[_vm._v("Payslip")])]):_vm._e(),(
                    _vm.fileData[0].personTwoFilePayslip &&
                      _vm.fileData[0].personTwoFilePayslip.length > 0 &&
                      _vm.fileData[0].personTwoFilePayslip !== 'undefined'
                  )?_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('v-spacer'),_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":("" + _vm.url + (_vm.fileData[0].personTwoFilePayslip)),"download":"","target":"_blank"}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-file-pdf-box")])],1)],1):_vm._e(),(
                    _vm.fileData[0].personTwoFileFica &&
                      _vm.fileData[0].personTwoFileFica.length > 0 &&
                      _vm.fileData[0].personTwoFileFica !== 'undefined'
                  )?_c('v-col',{attrs:{"cols":"6","sm":"6","md":"6"}},[_c('span',[_vm._v("Proof of Address")])]):_vm._e(),_c('ul',[(
                      _vm.fileData[0].personTwoFileFica &&
                        _vm.fileData[0].personTwoFileFica.length > 0 &&
                        _vm.fileData[0].personTwoFileFica !== 'undefined'
                    )?_c('v-col',{attrs:{"cols":"4","sm":"4","md":"4"}},[_c('v-spacer'),_c('a',{staticStyle:{"text-decoration":"none"},attrs:{"href":("" + _vm.url + (_vm.fileData[0].personTwoFilePayslip)),"download":"","target":"_blank"}},[_c('v-icon',{attrs:{"color":"green"}},[_vm._v("mdi-file-pdf-box")])],1)],1):_vm._e()],1)],1)],1)],1),_c('v-card-actions',[_c('v-spacer'),_c('v-btn',{attrs:{"color":"blue darken-1","text":""},on:{"click":_vm.closeClientFiles}},[_vm._v(" Close ")])],1)],1)],1)],1)],1)])}
var staticRenderFns = []

export { render, staticRenderFns }